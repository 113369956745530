import React, {Suspense, useEffect} from 'react';
import App from './AppExperiment';
import { createRoot } from 'react-dom/client';
import { Logo } from './Logo'
import { useProgress } from "@react-three/drei";

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <div id="logoWrapper" className="hidden" style={{ position: 'absolute', top: "20px", left: "20px", pointerEvents: 'none', width: '50%', display: "flex", alignItems: "center", justifyContent: "left" }}>
        <Logo/>
      </div>
      { /* 
      <a href="https://pmnd.rs/" style={{ position: 'absolute', bottom: 40, left: 40, fontSize: '13px' }}>
        some
        <br />
        text
      </a>
      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>1/1/2023</div>
      */ }
    </div>
  )
}

function LoadingDone() {
  useEffect( () => () => {
    console.log("unmounting")
    setTimeout(() => {
      document.getElementById("revealOverlay").classList.add("hide");        
      setTimeout(() => {
        document.getElementById("logoWrapper").classList.add("showFromTop");    
      }, 850)      
    }, 550)      
    //document.getElementById("initialOverlay").classList.add("hide");    
  }, [] );
  return <></>;
}

function InitialOverlay() {
  const { active, progress, errors, item, loaded, total } = useProgress()

  return (
    <div id="initialOverlay" style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%', background: "#000000"}}>
      <div style={{ position: 'absolute', top: "100px", left: 0, pointerEvents: 'none', width: '100%', display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Logo/>
      </div>
      <div style={{ position: 'absolute', bottom: "100px", left: 0, pointerEvents: 'none', width: '100%', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <h2 style={{marginBottom: "10px"}}>{Math.round(progress)}%</h2>
        <p style={{marginTop: "0"}}>Loading your experience</p>
      </div>
      { /* 
      <a href="https://pmnd.rs/" style={{ position: 'absolute', bottom: 40, left: 40, fontSize: '13px' }}>
      some
      <br />
      text
      </a>
      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>1/1/2023</div>
      */ }
    </div>
  )
}

const root = createRoot(document.getElementById('root'));
root.render(
  <>    
    <Suspense fallback={<LoadingDone/>}>
      <App />
      <div className="visible" id="revealOverlay">        
      </div>
      <Overlay />        
    </Suspense>    
    {/* <InitialOverlay/> */}
  </>
);
