import * as THREE from 'three'
import { useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useIntersect, Image, Scroll } from '@react-three/drei'

function Item({ url, scale, ...props }) {
  const visible = useRef(false)  
  const [hovered, hover] = useState(false)
  const ref = useIntersect((isVisible) => (visible.current = isVisible))
  const { height } = useThree((state) => state.viewport)
  useFrame((state, delta) => {
    ref.current.position.y = THREE.MathUtils.damp(ref.current.position.y, visible.current ? 0 : -height / 2 + 2, 1, delta)
    ref.current.material.zoom = THREE.MathUtils.damp(ref.current.material.zoom, visible.current ? 1 : 1.5, 1, delta)
    ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, visible.current ? 0 : 1, 1, delta/10)
  })
  return (
    <group {...props}>
      <Image ref={ref} grayscale={1} onPointerOver={() => hover(true)} onPointerOut={() => hover(false)} scale={scale} url={url} />
    </group>
  )
}

export default function Photos() {
  const { width: w, height: h } = useThree((state) => state.viewport)
  const initPosition = -h*2

  return (
    <Scroll>
      <Item url="/photos/1.jpg" scale={[w / 2, w / 2, 1]} position={[-w / 6, initPosition, 0]} />
      <Item url="/photos/2.jpg" scale={[2, w / 3, 1]} position={[w / 3, initPosition-h*0.4, 0]} />
      <Item url="/photos/3.jpg" scale={[w / 3, w / 5, 1]} position={[-w / 4, initPosition -h * 0.7, 0]} />
      <Item url="/photos/4.jpg" scale={[w / 5, w / 5, 1]} position={[w / 4, initPosition -h * 1.1, 0]} />
      <Item url="/photos/5.jpg" scale={[w / 5, w / 5, 1]} position={[w / 10, initPosition -h * 1.35, 0]} />
      <Item url="/photos/6.jpg" scale={[w / 3, w / 3, 1]} position={[-w / 4, initPosition -h * 1.5, 0]} />
      <Item url="/photos/7.jpg" scale={[w / 3, w / 5, 1]} position={[w / 50, initPosition -h * 1.8, 0]} />
      <Item url="/photos/8.jpg" scale={[w / 2, w / 2, 1]} position={[w / 4, initPosition -h * 2.4, 0]} />
      <Item url="/photos/9.jpg" scale={[w / 2.5, w / 2, 1]} position={[-w / 6, initPosition -h * 3.1, 0]} />
    </Scroll>
  )
}
