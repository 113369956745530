import React, { useEffect } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh } from "three";
import { useGLTF, useTexture } from '@react-three/drei'

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 wooden_table.glb
Author: Hrisan (https://sketchfab.com/Hrisan)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/wooden-table-7fc479c22ffa4a0eb4000390ce17170e
Title: Wooden table
*/


export function Table(props) {
  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + "models/tables/leather_armchair_coffee_table_floorlamp-transformed.glb")

  return (
    <group position={[0, -0.0, 0]} rotation={[ 0, -Math.PI, 0 ]} scale={1} {...props} dispose={null}>
      <group scale={0.01}>
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <group position={[80.27, 19.27, 138.46]} rotation={[-0.02, 0.05, 0.69]} scale={1.03}>
            <mesh castShadow receiveShadow geometry={nodes.FLOORLAMP_TEXSET_A_0.geometry} material={materials.TEXSET_A} position={[0, 0, -10.32]} />
          </group>
          <mesh castShadow receiveShadow geometry={nodes.ARMCHAIR_TEXSET_A_0.geometry} material={materials.TEXSET_A} rotation={[0, 0, 0.19]} />
          <mesh castShadow receiveShadow geometry={nodes.COFFEETABLE_TEXSET_A_0.geometry} material={materials.TEXSET_A} position={[23.31, -100.42, 0]} rotation={[0, 0, 0.07]} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(process.env.PUBLIC_URL + "models/tables/leather_armchair_coffee_table_floorlamp-transformed.glb")


// based on "Chevrolet Corvette (C7)" (https://sketchfab.com/3d-models/chevrolet-corvette-c7-2b509d1bce104224b147c81757f6f43a) 
// by Martin Trafas (https://sketchfab.com/Bexxie) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
/* 
export function Table() {
  const gltf = useLoader(
    GLTFLoader,
    process.env.PUBLIC_URL + "models/tables/wooden_table.glb"
  );
  
  useEffect(() => {
    gltf.scene.scale.set(0.005, 0.005, 0.005);
    gltf.scene.position.set(0, -0.035, 0);
    gltf.scene.traverse((object) => {
      if (object instanceof Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        object.material.envMapIntensity = 20;
      }
    });
  }, [gltf]);

  useFrame((state, delta) => {
    let t = state.clock.getElapsedTime();

    let group = gltf.scene.children[0].children[0].children[0];
    group.children[0].rotation.x = t * 2;
    group.children[2].rotation.x = t * 2;
    group.children[4].rotation.x = t * 2;
    group.children[6].rotation.x = t * 2;
  }); 

  return <Model />;
}
*/