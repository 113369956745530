import React, { useEffect } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { MeshReflectorMaterial } from "@react-three/drei";
import { LinearEncoding, RepeatWrapping, TextureLoader } from "three";
import { useControls } from "leva"

export function Ground() {
  const { impl, ...config } = useControls({
    ground: true,
    envMapIntensity:{ value: 1},
    normalScale:{ value: [0.35, 0.35]},
    dithering:{ value: true},
    color:{ value: [0.025, 0.025, 0.025]},
    roughness:{ value: 1.25},
    blur:{ value: [500, 400]}, // Blur ground reflections (width, heigt), 0 skips blur
    mixBlur:{ value: 30}, // How much blur mixes with surface roughness (default : 1)
    mixStrength:{ value: 40}, // Strength of the reflections
    mixContrast:{ value: 1}, // Contrast of the reflections
    resolution:{ value: 1024}, // Off-buffer resolution, lower:faster, higher:better quality, slower
    mirror:{ value: 0}, // Mirror environment, 0 : texture colors, 1 : pick up env colors
    depthScale:{ value: 0.01}, // Scale the depth factor (0 : no depth, default : 0)
    minDepthThreshold:{ value: 0.9}, // Lower edge for the depthTexture interpolation (default : 0)
    maxDepthThreshold:{ value: 1}, // Upper edge for the depthTexture interpolation (default : 0)
    depthToBlurRatioBias:{ value: 0.25}, // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor : blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
    reflectorOffset:{ value: 0}, //
  })

  // thanks to https://polyhaven.com/a/rough_plasterbrick_05 !
  const [roughness, normal] = useLoader(TextureLoader, [
    process.env.PUBLIC_URL + "textures/terrain-roughness.jpg",
    process.env.PUBLIC_URL + "textures/terrain-normal.jpg", 
/*     process.env.PUBLIC_URL + "textures/ground/concrete_floor_worn_001_nor_gl_4k.jpg",
    process.env.PUBLIC_URL + "textures/ground/concrete_floor_worn_001_rough_4k.jpg", */
  ]);

  useEffect(() => {
    [normal, roughness].forEach((t) => {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(5, 5);
      t.offset.set(0, 0);
    });

    normal.encoding = LinearEncoding;
  }, [normal, roughness]);

  useFrame((state, delta) => {
    let t = -state.clock.getElapsedTime() * 0.128;
  /*   roughness.offset.set(0, t % 1);
    normal.offset.set(0, t % 1); */
  });

  return (
    <mesh position={[0, 0, 0]} rotation-x={-Math.PI * 0.5} receiveShadow>
      <planeGeometry args={[30, 30]} />
      <MeshReflectorMaterial
        normalMap={normal}
        roughnessMap={roughness}
        debug={0}
        {...config}
        /* envMapIntensity={0}
        normalScale={[0.15, 0.15]}
        dithering={true}
        color={[0.015, 0.015, 0.015]}
        roughness={0.7}
        blur={[500, 400]} // Blur ground reflections (width, heigt), 0 skips blur
        mixBlur={30} // How much blur mixes with surface roughness (default = 1)
        mixStrength={40} // Strength of the reflections
        mixContrast={1} // Contrast of the reflections
        resolution={1024} // Off-buffer resolution, lower=faster, higher=better quality, slower
        mirror={0} // Mirror environment, 0 = texture colors, 1 = pick up env colors
        depthScale={0.01} // Scale the depth factor (0 = no depth, default = 0)
        minDepthThreshold={0.9} // Lower edge for the depthTexture interpolation (default = 0)
        maxDepthThreshold={1} // Upper edge for the depthTexture interpolation (default = 0)
        depthToBlurRatioBias={0.25} // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor = blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
        reflectorOffset={0.2} // Offsets the virtual camera that projects the reflection. Useful when the reflective surface is some distance from the object's origin (default = 0) */
      />
    </mesh>
  );
}
