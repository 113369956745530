import React, { useEffect, useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh } from "three";
import { useGLTF, useTexture } from '@react-three/drei'

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 wooden_table.glb
Author: Hrisan (https://sketchfab.com/Hrisan)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/wooden-table-7fc479c22ffa4a0eb4000390ce17170e
Title: Wooden table
*/


export function Table(props) {
  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + "models/tables/wooden_table.glb")
  const theMesh = useRef();

  // https://docs.pmnd.rs/react-three-fiber/tutorials/loading-textures
  // https://github.com/pmndrs/drei#usetexture
/*   const [colorMap, roughnessMap] = useTexture([ 
    "./textures/table/cherry-wood-veneer2-albedo.png",
    "./textures/table/cherry-wood-veneer2-Roughness.png",    
  ])  */
/* 
  useEffect(() => {
    theMesh.current.material.transparent = true;
    theMesh.current.material.opacity = 0;
  }, []) */

  return (
    <group position={[0, -0.0, 0]} rotation={[ 0, 0, 0 ]} scale={0.25} {...props} dispose={null}>
      <mesh ref={theMesh} castShadow receiveShadow geometry={nodes.Object_4.geometry} material={materials.Material} position={[0, 2.4, 0]} >
      </mesh>
    </group>
  )
}

useGLTF.preload(process.env.PUBLIC_URL + "models/tables/wooden_table.glb")


// based on "Chevrolet Corvette (C7)" (https://sketchfab.com/3d-models/chevrolet-corvette-c7-2b509d1bce104224b147c81757f6f43a) 
// by Martin Trafas (https://sketchfab.com/Bexxie) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
 /*
export function Table() {
  
  const gltf = useLoader(
    GLTFLoader,
    process.env.PUBLIC_URL + "models/tables/wooden_table.glb"
  );
  
  useEffect(() => {
    gltf.scene.scale.set(0.005, 0.005, 0.005);
    gltf.scene.position.set(0, -0.035, 0);
    gltf.scene.traverse((object) => {
      if (object instanceof Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        object.material.envMapIntensity = 20;
      }
    });
  }, [gltf]);

  useFrame((state, delta) => {
    let t = state.clock.getElapsedTime();

    let group = gltf.scene.children[0].children[0].children[0];
    group.children[0].rotation.x = t * 2;
    group.children[2].rotation.x = t * 2;
    group.children[4].rotation.x = t * 2;
    group.children[6].rotation.x = t * 2;
  }); 

  return <Model />;
}
*/