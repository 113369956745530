import { useLoader } from '@react-three/fiber'
import { EffectComposer, DepthOfField, ChromaticAberration, Bloom, LUT, Vignette } from '@react-three/postprocessing'
import { useControls } from 'leva'
import { LUTCubeLoader, BlendFunction, BlurPass } from 'postprocessing'
//import { BlendFunction } from "postprocessing";

export function Effects() {
  const texture = useLoader(LUTCubeLoader, '/F-6800-STD.cube')

  return (
    true && (
      // important to enable stencil buffer
      <EffectComposer dithering  stencilBuffer={true} >          
        { false && 
          <>
            <DepthOfField focusDistance={0.0006} focalLength={0.004} bokehScale={4} height={600} />        
          </>
        }
        <ChromaticAberration
          blendFunction={BlendFunction.NORMAL} // blend mode
          offset={[0.0005, 0.0012]} // color offset
        />
        <Bloom luminanceThreshold={0.01} mipmapBlur luminanceSmoothing={0} intensity={1} blurPass={new BlurPass()}/>
        <LUT lut={texture} />
        <Vignette eskil={false} offset={0.1} darkness={0.9} />        
        {/* <DepthOfField focusDistance={0.0035} focalLength={0.01} bokehScale={3} height={480} /> 
        <Bloom
          blendFunction={BlendFunction.ADD}
          intensity={1.3} // The bloom intensity.
          width={300} // render width
          height={300} // render height
          kernelSize={5} // blur kernel size
          luminanceThreshold={0.15} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
        />
        */}
          {/*
        <ChromaticAberration
          blendFunction={BlendFunction.NORMAL} // blend mode
          offset={[0.0005, 0.0012]} // color offset
        />
        <Sepia
          intensity={0.6} // sepia intensity
        />
        */}
    
      </EffectComposer>   
    )
  )
}
