// https://www.youtube.com/watch?v=lcMCVWYpnrI
import {MathUtils, Vector3} from "three";
import React, { useEffect, useState, useRef } from "react";
import useRefs from 'react-use-refs'
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {          
  useScroll,
  ScrollControls,
  Scroll,      
  PerformanceMonitor,  
  SpotLight,      
  RenderTexture,
  Mask, 
  useMask, 
  AdaptiveDpr
} from "@react-three/drei";
import { Leva } from "leva"

//import studio from '@theatre/studio'
//import extension from '@theatre/r3f/dist/extension'

import {getProject, val} from '@theatre/core'
import {PerspectiveCamera, OrthographicCamera, editable as e, SheetProvider} from '@theatre/r3f'

import "./style.css";
import { Table } from "./Table";
import { Table as Table4k } from "./Table4k";
import { Table as TableArmchair } from "./TableArmchair";
import { Ground } from "./Ground";
import { Effects } from "./Effects"
import Photos from "./Photos"

import animationState from "./Animation State.json"

const damp = MathUtils.damp;
const pages = 6;

//const demoSheet = getProject('Demo Project').sheet('Demo Sheet');
const demoSheet = getProject("Demo Project", {state: animationState}).sheet("Demo Sheet");
const initialLoadSheet = getProject("Demo Project", {state: animationState}).sheet("Initial Load");
const generalSheet = getProject("Demo Project", {state: animationState}).sheet("General Sheet");

/* studio.extend(extension)    
studio.initialize()        */

function MovingSpot({ vec = new Vector3(), ...props }) {
  const light = useRef()
  const viewport = useThree((state) => state.viewport)
  useFrame((state) => {
    light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0), 0.1)
    light.current.target.updateMatrixWorld()
  })
  return <SpotLight castShadow ref={light} penumbra={1} distance={6} angle={0.35} attenuation={5} anglePower={4} intensity={2} {...props} />
}

function CarShow() {
  const stuff = useRef();
  const lamp = useRef();
  const scroll = useScroll()
  const cuttingBoard = useRef();
  const tl = useRef()  
  const tables = [
    <TableArmchair key="table1"/>,
    <Table key="table2"/>,
    <Table4k key="table3"/>,
  ]
  const [positions, setPositions] = useState(Array.from({length: tables.length}, (v, i) => i == 0 ? 0 : 20))
  const [currentTable, setCurrentTable] = useState(tables[0])
  const tableId = useRef(0)
  const prevRange = useRef(0);
  const [firstRender, setFirstRender] = useState(true)
  const numPagesForThis = 3;
  const { camera, mouse } = useThree()  
  
  // need to use this and set the position without using state to avoid rerender
  function makePositionVisible(index) {
    const newPositions = positions.map((c, i) => {
      if (i === index) {
        // Increment the clicked counter
        return 0;
      } else {
        return 20;
      }
    });
    setPositions(newPositions);
  }

  useFrame((state, delta) => {
    // this enables control of sequence with scrolling    
    const currentScrollRange = scroll.range(0, numPagesForThis/pages);
    // not scrolling

    
    const text1 = document.getElementById("textContent1");
    const text2 = document.getElementById("textContent2");
    const text3 = document.getElementById("textContent3");    

    //text1.style.transform = `translate(0, ${scroll.range(0, 0.03)*100}px)`;
    // https://stackoverflow.com/questions/38454240/using-css-important-with-javascript#38454576    
    if (text1) {
      text1.style.setProperty("opacity", scroll.range(-0.5, 0.03) == 0 ? 0 : 1 - scroll.range(0, 0.03), "important");
      
      //text2.style.transform = `translate(0, ${scroll.range(0.2, 0.03)*100}px)`;
      text2.style.setProperty("opacity", scroll.curve(1/12, 1/12), "important");

      text3.style.setProperty("opacity", scroll.curve(2.5/12, 1/12), "important");
    }


    if (prevRange.current == currentScrollRange) return;

    demoSheet.sequence.position = val(demoSheet.sequence.pointer.length) * currentScrollRange;    
    const numTables = tables.length;

    const divider = pages * 2;
    const initFactor = 0.95 / divider;
    const increment = 0.07;   
    

    const goingDown = prevRange.current < currentScrollRange;


    //const firstIncrement = 1 / numTables
    if (goingDown) {
      if (scroll.visible(0.05, increment)) {  
        if (tableId.current != 1) {
          tableId.current = 1;
          setCurrentTable(tables[1])
        }        
      } else if (scroll.visible(0.04 + 1/8, increment)) {
        if (tableId.current != 2) {
          tableId.current = 2;
          setCurrentTable(tables[2])
        }
      } 
    } else {
      if (scroll.visible(0.0, 0.06)) {  
        if (tableId.current != 0) {
          tableId.current = 0;
          setCurrentTable(tables[0])
        }        
      } else if (scroll.visible(0.04 + 1/12, increment)) {
        if (tableId.current != 1) {
          tableId.current = 1;
          setCurrentTable(tables[1])
        }
      } 
    }
   
    prevRange.current = currentScrollRange;
    //let t = -state.clock.getElapsedTime() * 0.128;
/* 
    let unit = 1/pages;    
    let offset = unit/5;
    
    let scrollFirstPart = scroll.range(0, unit);
    let scrollSecondPart = scroll.range(unit + offset, unit - offset);
    let amount = scrollFirstPart * 2.3 - scrollSecondPart * 2.54;
    
    //let amountLocation = scroll.range(0, 1 / 3) * 0.0 ;
    stuff.current.rotation.y = damp(stuff.current.rotation.y, amount, 10, delta)
    //stuff.current.position.z = damp(stuff.current.position.z, amountLocation, 10, delta)

    let scrollThirdPart = (scroll.range(1.90 / 3, 1/3));
    amount = scrollThirdPart*-1.5;
    stuff.current.position.x = damp(stuff.current.position.x, amount, 10, delta)
    

    // lamp final position [ 1, 1.23, 1]
    const lampLocationY = 1.23;
    scrollFirstPart = ((1 - scroll.range(0, unit)));
    offset = unit / 8;
    scrollSecondPart = (scroll.range(unit + offset, unit - offset));
    amount = 1.23 + (scrollFirstPart + scrollSecondPart)*10;
    //let goalY = MathUtils.lerp(lamp.current.position.y, lampLocationY, amountLocationLamp);
    lamp.current.position.y = damp(lamp.current.position.y, amount, 10, delta);

    offset = offset + 0.1;
    scrollFirstPart = ((1 - scroll.range(0, unit + offset)));
    scrollSecondPart = (scroll.range(unit + offset, unit - offset));
    amount = 1.23 + (scrollFirstPart + scrollSecondPart)*10;
    cuttingBoard.current.position.y = damp(cuttingBoard.current.position.y, amount, 10, delta);
    //tl.current.seek(scroll.offset * tl.current.duration())
 */

  });

/*   useLayoutEffect(()=> {
    tl.current = gsap.timeline({defaults: {duration: 2, ease: 'power1.easeIn'}})

    tl.current
    .to(camera.current.position, {x: -4}, 1)        
    .to(camera.current.position, {x: -3}, 2)        
    .to(camera.current.position, {x: -2}, 3)        
    .to(camera.current.position, {x: -1}, 4)        
    .to(camera.current.position, {x: 5}, 5)        
  },[]) */

  useEffect(() => {
    setFirstRender(false);
  }, [])

  return (
    <>
        <e.group theatreKey="Pivot" nudgeMultiplier="0.01" ref={stuff}>          
            <e.group theatreKey="Table">                        
              { currentTable }
              { firstRender && tables.map( table => table ) }
            </e.group>
          <Ground />
          { true && 
            <mesh scale={3.0} position={[1.3, 0.01, 0.7]} rotation={[-Math.PI / 2, 0, Math.PI / 3.5]}>
              <ringGeometry args={[0.9, 1, 3, 1]} />
              <meshStandardMaterial color="cyan" emissive="cyan" emissiveIntensity={3} roughness={0.75} toneMapped={ false } />              
            </mesh>
          }          
        </e.group>
             
      { /* 
        <CuttingBoard ref={cuttingBoard}/>
        <Lamp ref={lamp} />
          */ }      
    </>
  );
}

function Stuff() {
  const viewport = useThree((state) => state.viewport)
  const runOnce = useRef(false);  
  const stencil = useMask(1, true)
  const stencil2 = useMask(1, false)

  useEffect(() => {
    // our Theatre.js project sheet, we'll use this later  
    if (!runOnce.current) {
      runOnce.current = true;          
       
      setTimeout(() => {
        initialLoadSheet.project.ready.then(() => initialLoadSheet.sequence.play({iterationCount: 1, range: [0, 4]}))
      }, 4000);
    }
  }, [])
  console.log("Rerendering");

  return (
    <ScrollControls pages={pages} distance={2} maxSpeed={0.3} damping={0.65}> 
      <SheetProvider sheet={demoSheet}>
        <e.group theatreKey="mask">        
          <Mask id={1} position={[0, 0, 0.95]}>          
              <planeGeometry args={[viewport.width*2, viewport.height*2]} />
          </Mask>
        </e.group>

        <e.mesh theatreKey="layer1" position={[0, 0, 0]} >
          <planeGeometry args={[viewport.width, viewport.height]} position={[0, 0, 0]} />
          <meshStandardMaterial {...stencil} dithering>
            <RenderTexture attach="map" anisotropy={16}>              
              <color args={[0, 0, 0]} attach="background" />            
              <SheetProvider sheet={initialLoadSheet}>
              { true &&          
                <>
                  <e.spotLight
                    theatreKey="Spotlight2"
                    color={[1, 0, 0.7]}
                    color={[0.9, 0.4, 0.2]}
                    intensity={1}
                    angle={0.6}
                    penumbra={0.5}
                    position={[5, 5, 0]}
                    castShadow
                    shadow-bias={-0.0001}
                  />          
                  <e.spotLight
                    theatreKey="Spotlight"
                    color={[0.14, 0.5, 1]}
                    color={[0.9, 0.4, 0.2]}
                    intensity={1}
                    angle={0.6}
                    penumbra={0.5}
                    position={[-5, 5, 0]}
                    castShadow      
                    shadow-bias={-0.0001}  
                  />
                </>
              }  
              </SheetProvider>
                { /* <fog attach="fog" args={['black', 2, 10.5]} /> */ }        
           
              <PerspectiveCamera theatreKey="Camera" makeDefault rotation={[ -2.7, 0, -Math.PI]} position={[0, 5, -5]} fov={50}/>
              <CarShow />                      
            </RenderTexture>
          </meshStandardMaterial>
        </e.mesh>

        <e.mesh theatreKey="layer2" position={[0, 0, 0]} >
          <planeGeometry args={[viewport.width, viewport.height]} position={[0, 0, 0]} />
          <meshStandardMaterial  {...stencil2} dithering >
            <RenderTexture gl={{ alpha: false, antialias: false, stencil: false, depth: false }} attach="map" anisotropy={16}>
              <OrthographicCamera theatreKey="Camera2" makeDefault position={[0, 0, 0.1]} zoom={400}/>
     
              <color attach="background" args={['#f0f0f0']} />
              <Photos />          
            </RenderTexture>
          </meshStandardMaterial >
        </e.mesh>
        <Scroll html style={{ width: '100%' }}>
          <div className="textWrapper">
            <div className="textBox">
              <div id="textContent1" className='textContent'>
                <h2>Custom Furniture</h2>
                <p>Handmade luxury furniture made with close attention to detail, craftsmanship and beautiful materials.</p>
                <button>Read more</button>
              </div>    
            </div>
            <div id="textBox1" className="textBox">
              <div id="textContent2" className='textContent'>
                <h2>Custom 3D Designs</h2>
                <p>We create accurate models, renderings and drawings for every project in Autodesk Fusion 360.</p>
                <button>Read more</button>
              </div> 
            </div> 
            <div id="textBox2" className="textBox" style={{position: "absolute", top: "160vh"}}>
              <div id="textContent3" className='textContent'>
                <h2>Timeless Pieces</h2>
                <p>Premade pieces ready for your home.</p>
                <button>Read more</button>
              </div>                      
            </div>
          </div>
          
          { /* <h2 style={{ position: 'absolute', top: '50vh', left: '50%', transform: `translate(-50%,-50%)` }}>Cutting-Edge of Grooming</h2> */}

          <h1 className="bigTitle" style={{ position: 'absolute', top: `260vh`, right: '20vw', transform: `translate3d(0,-100%,0)` }}>Woodworking</h1>
          <h1 className="bigTitle" style={{ position: 'absolute', top: '330vh', left: '10vw' }}>at</h1>
          <h1 className="bigTitle" style={{ position: 'absolute', top: '410vh', right: '10vw' }}>its</h1>
          <h1 className="bigTitle" style={{ position: 'absolute', top: '450vh', left: '10vw' }}>finest</h1>
          {/* <h1 className="bigTitle" style={{ position: 'absolute', top: '500vh', right: '10vw' }}>
            finest
            <br />
            .
          </h1> */}
        </Scroll>
      </SheetProvider>
    </ScrollControls>
  )
}

function App() {
  const [degraded, degrade] = useState(false)

  return (   
    <Canvas frameloop="demand" gl={{ antialias: false }}  shadows camera={{ position: [0, 0, 5], fov: 25 }}>
      <AdaptiveDpr pixelated />
      <color args={[0, 0, 0]} attach="background" />      
      <Leva collapsed hidden/>
      <PerformanceMonitor onDecline={() => degrade(true)} />
      <directionalLight position={[10, 10, 5]} />
    
      <Stuff/>
            
      { true && 
        <Effects />
      }

    </Canvas>
  );
}

function Frame(props) {
  return (
    <mesh {...props}>
      <planeGeometry args={[11, 11]} />
      <meshPhongMaterial color="white" />
    </mesh>
  )
}

export default App;
